<template>
  <div>
    <div align='center'>
      <h1>
        APSTH Cloud Platform Open API
      </h1>
    </div>
    <v-row>
      <v-col
        cols='12'
        lg='5'
      >
        <h3 class='mt-11'>
          {{ $t('open_api1') }}
        </h3>
        <v-divider class='my-2'></v-divider>
        <p>
          <v-icon color='primary'>
            {{ icons.mdiCheckCircle }}
          </v-icon>
          {{ $t('apirow1') }}
        </p>
        <p>
          <v-icon color='primary'>
            {{ icons.mdiCheckCircle }}
          </v-icon>
          {{ $t('apirow2') }}
        </p>
        <p>
          <v-icon color='primary'>
            {{ icons.mdiCheckCircle }}
          </v-icon>
          {{ $t('apirow3') }}
        </p>
        <p>
          <v-icon color='primary'>
            {{ icons.mdiCheckCircle }}
          </v-icon>
          {{ $t('apirow4') }}
        </p>
        <p>
          <v-icon color='primary'>
            {{ icons.mdiCheckCircle }}
          </v-icon>
          {{ $t('apirow5') }}
        </p>
        <p>
          <v-icon color='primary'>
            {{ icons.mdiCheckCircle }}
          </v-icon>
          {{ $t('apirow6') }}
        </p>
        <p>
          <v-icon color='primary'>
            {{ icons.mdiCheckCircle }}
          </v-icon>
          {{ $t('apirow7') }}
        </p>
      </v-col>
      <v-col
        cols='12'
        lg='7'
      >
        <div class='text-right mb-2'>
          <v-btn
            color='primary'
            href='https://api-apsth.co/'
            target='_blank'
          >
            Docs API
          </v-btn>
        </div>
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr>
                <td class='font-weight-bold'>
                  {{ $t('shop_code') }}:
                </td>
                <td>
                  {{ data.shop_id }}
                </td>
              </tr>
              <tr>
                <td class='font-weight-bold'>
                  {{ $t('shop_name') }}:
                </td>
                <td>
                  {{ data.shop_name }}
                </td>
              </tr>
              <tr>
                <td class='font-weight-bold'>
                  {{ $t('expire_date') }}:
                </td>
                <td>
                  <span v-if="$i18n.locale == 'th'">{{ data.api_expire |sumdate }}</span>
                  <span v-if="$i18n.locale == 'en'">{{ data.api_expire |sumdateEn }}</span>
                </td>
              </tr>
              <tr>
                <td class='font-weight-bold'>
                  {{ $t('number_of_the_day') }}{{ $t('balance') }}:
                </td>
                <td>
                  {{ data.expire }} {{ $t('day') }}
                </td>
              </tr>
              <tr>
                <td class='font-weight-bold'>
                  {{ $t('status') }}:
                </td>
                <td>
                    <span v-if="data.api_is_active=='0'">
                      {{ $t('not_create') }}
                    </span>
                  <span v-if='data.api_is_active==1'>
                      {{ $t('normal') }}
                    </span>
                  <span v-if='data.api_is_active==2'>
                      {{ $t('suspend') }}
                    </span>
                </td>
              </tr>
              <tr>
                <td
                  class='font-weight-bold d-flex'
                >
                  <span>Public Key:</span>
                </td>
                <td>
                  {{ data.api_key_public }}
                  <v-tooltip
                    color='#212121'
                    top
                  >
                    <template v-slot:activator='{ on, attrs }'>
                      <v-icon
                        v-clipboard:copy='data.api_key_public'
                        v-clipboard:success='copy'
                        v-bind='attrs'
                        color='primary'
                        style='cursor:pointer'
                        v-on='on'
                      >
                        {{ icons.mdiContentCopy }}
                      </v-icon>
                    </template>
                    <span>{{ $t('Copy') }}</span>
                  </v-tooltip>
                </td>
              </tr>
              <tr>
                <td class='font-weight-bold d-flex'>
                  <span>Private Key:</span>
                </td>
                <td class=''>
                  {{ data.api_key_private }}
                  <v-tooltip
                    color='#212121'
                    top
                  >
                    <template v-slot:activator='{ on, attrs }'>
                      <v-icon
                        v-clipboard:copy='data.api_key_private'
                        v-clipboard:success='copy'
                        v-bind='attrs'
                        color='primary'
                        style='cursor:pointer'
                        v-on='on'
                      >
                        {{ icons.mdiContentCopy }}
                      </v-icon>
                    </template>
                    <span>{{ $t('Copy') }}</span>
                  </v-tooltip>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-divider></v-divider>
        <div class='mt-6 font-weight-bold'>
          Line@ (Official Account) for APS Platform Clinic
        </div>
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr>
                <td>
                  <span class='font-weight-bold'> LIFF Link: </span>

                  <span class='ml-15'>https://liff.line.me/1656588929-rkkqx9w1</span>
                  <v-tooltip
                    color='#212121'
                    top
                  >
                    <template v-slot:activator='{ on, attrs }'>
                      <v-icon
                        v-clipboard:copy='liffLink'
                        v-clipboard:success='copy'
                        v-bind='attrs'
                        color='primary'
                        style='cursor:pointer'
                        v-on='on'
                      >
                        {{ icons.mdiContentCopy }}
                      </v-icon>
                    </template>
                    <span>{{ $t('Copy') }}</span>
                  </v-tooltip>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiContentCopy, mdiCheckCircle } from '@mdi/js'
import { sumdate, sumdateEn } from '@/plugins/filters'
import store from '@/store'
import apiKey from '@/api/apiKey'

export default {

  filters: {
    sumdate,
    sumdateEn,
  },

  setup() {
    const dialog = ref(false)
    const statusLoadingData = ref(false)
    const data = ref({})
    const liffLink = ref('https://liff.line.me/1656588929-rkkqx9w1')

    onMounted(() => {
      getApiKey()
    })

    const copy = () => {
      store.commit('app/ALERT', {
        message: 'coppySuccess',
        color: 'success',
      })
    }

    const { apiKeyGet } = apiKey
    const getApiKey = () => {
      statusLoadingData.value = true
      apiKeyGet().then(res => {
        data.value = res
        statusLoadingData.value = false
      })
    }

    return {
      dialog,
      statusLoadingData,
      data,
      liffLink,
      mdiContentCopy,
      copy,
      icons: {
        mdiContentCopy,
        mdiCheckCircle,
      },
    }
  },

}
</script>
