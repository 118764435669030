import refreshToken from '@/plugins/refresh-token'
import { api } from './main'

const apiKeyGet = async () => {
  await refreshToken()
  const response = await api
    .get({
      path: 'shop/keyapi',
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get key api error : ', err)

      return []
    })

  return response
}
export default { apiKeyGet }
